<template>
  <div id="paypal-redirect">
    <div class="thank-you">
      <h1>Thank You for Affiliating!</h1>
      <span
        >As an affiliate, HFA will handle your U.S. mechanical licensing and
        worldwide royalty collections, data management, and YouTube™
        monetization.</span
      >

      <AffiliateBenefits />
    </div>

    <div class="buttons">
      <router-link to="/" class="button default"
        >&lt;&nbsp;Back to home</router-link
      >
    </div>
  </div>
</template>
<script>
import AffiliateBenefits from "@/components/AffiliateBenefits.vue";

export default {
  name: "PaypalRedirect",
  components: {
    AffiliateBenefits
  }
};
</script>
<style lang="scss">
#paypal-redirect {
  .thank-you {
    text-align: center;
    font-size: 1.2em;
    margin: 20px 0 0;

    .benefits-container {
      margin-top: 50px;

      #benefits {
        margin-bottom: 0;

        .benefit {
          margin-bottom: 10px;

          h2,
          p {
            font-size: 0.85em;
          }
        }

        .action {
          display: none;
        }
      }
    }
  }
  .buttons {
    font-size: 1.2em;
    margin: 20px 0 20px;
    text-align: center;
  }
}
</style>
